export const PRODUCT_ACTION_BUTTON_VIEWONMAP_FRAGMENT = `
  fragment productActionButtonViewonmapFragment on Exhibitor {
    uid
    typename: __typename
    _geozonesOfEdition(editionSchemaCode: "%schemaCodeViewMap%") {
      uid
      id
      schemaCode
      name
      exhibitHall {
        id
      }
    }
  }
`;
